import * as Sentry from '@sentry/browser';
import getSentryEnv from './utils/sentryEnv';
import axios from 'axios';

// Not sure if this is useful but I followed
// https://docs.sentry.io/platforms/javascript/guides/vue/best-practices/micro-frontends/
const EXTRA_KEY = 'ROUTE_TO';

const transport = Sentry.makeMultiplexedTransport(Sentry.makeFetchTransport, args => {
  const event = args.getEvent();
  if (
    event &&
    event.extra &&
    EXTRA_KEY in event.extra &&
    Array.isArray(event.extra[EXTRA_KEY])
  ) {
    return event.extra[EXTRA_KEY];
  }
  return [];
});

Sentry.init({
    dsn: 'https://cd8a34b1f85f4d08b726ca07e6f6bd43@sentry.io/77802',
    transport,
    environment: getSentryEnv(),
    integrations: [
        Sentry.browserTracingIntegration({
            enableInp: true,
        }),
        Sentry.moduleMetadataIntegration(),
    ],

    beforeSend: event => {
        if (event?.exception?.values?.[0].stacktrace?.frames) {
          const frames = event.exception.values[0].stacktrace.frames;
          // Find the last frame with module metadata containing a DSN
          const routeTo = frames
            .filter(frame => frame.module_metadata && frame.module_metadata.dsn)
            .map(v => v.module_metadata)
            .slice(-1); // using top frame only - you may want to customize this according to your needs

          if (routeTo.length) {
            event.extra = {
              ...event.extra,
              [EXTRA_KEY]: routeTo,
            };
          }
        }

        return event;
      },

    // We are keeping the tracing rate low for now, to not exceed our quota.
    tracesSampleRate: 0.01,

    // We only use tracing for frontend performance monitoring.
    // Tracing requests is not really relevant to us at the moment.
    tracePropagationTargets: [],
});

/**
 * Polyfills (mostly for IE11)
 */
import 'picturefill';

/**
 * Features
 */
import Navigation from './features/Navigation';
import AppLoader from './features/AppLoader';
import DailyLeads from './features/DailyLeads';
import JobForm from './features/JobForm';
import TrustPilot from './features/TrustPilot';
import NewsletterForm from './features/NewsletterForm';
import ListFilter from './features/ListFilter';
import TechnologyDesktop from './features/Technology';
import VideoContent from './features/VideoContent';
import VideoPlayerHandler from './features/videoPlayerHandler';
import customerReferral from './features/CustomerReferral';
import customerReferralResult from './features/CustomerReferralResult';
import customerReferralEmail from './features/CustomerReferralEmail';
import conversionBanner from './features/ConversionBanner';
import invertCTA from './features/InvertCTA';
import header from './features/Header';
import scrollTo from './features/ScrollTo';
import lazyLoad from './features/LazyLoad';
import tracking from './features/Tracking';
import recruitingChannel from './features/RecruitingChannel';
import usercentricsConfig from './features/UsercentricsConfig';
import accordion from './features/Accordion';
import engagementOnPageLeadForm from './features/EngagementOnPageLeadForm';
import notificationBanner from './features/NotificationBanner';
import modal from './features/Modal';
import popover from './features/Popover';
import copyToClipboard from './features/CopyToClipboard';
import antiScam from './features/AntiScam';
import monitorCssAvailability from './features/MonitorCssAvailability';
import carousel from './features/Carousel';
import tabs from './features/Tabs';
import ZipCodeForm from './features/ZipCodeForm';
import PressMailingForm from './features/PressMailingForm';
import IndicationPriceCalculator from './features/IndicationPriceCalculator';
import FlexRateCalculator from './features/FlexRateCalculator';

import abTestSession from './utils/abTestSession';

/**
 * AB Tests
 */
//
import MT3280 from './abtests/MT3280';

/**
 * Exposed globally, for on-demand use.
 */
window.TNS.website = {
    // Classes
    AppLoader,
    DailyLeads,
    JobForm,
    ListFilter,
    VideoContent,
    ZipCodeForm,
    PressMailingForm,
    IndicationPriceCalculator,
    FlexRateCalculator,

    // Functions
    customerReferral,
    customerReferralResult,
    customerReferralEmail,
};

/**
 * Executed on load of every pages.
 */
const setup = () => {
    /**
     * Navigation
     */
    if(document.querySelector('[data-navigation')) {
        new Navigation();
    }

    /**
     * Newsletter
     */
    if (document.querySelector('[data-form-newsletter]')) {
        // eslint-disable-next-line no-new
        new NewsletterForm();
    }

    /**
     * Technology
     */
    if (document.querySelector('[data-technology-desktop]')) {
        // eslint-disable-next-line no-new
        new TechnologyDesktop();
    }

    /**
     * Video player
     */
    if (document.querySelector('[data-video-player]')) {
        // eslint-disable-next-line no-new
        new VideoPlayerHandler();
    }

    /**
     * TrustPilot
     */
    if (document.querySelector('[data-trustpilot]')) {
        // eslint-disable-next-line no-new
        new TrustPilot();
    }

    /**
     * Engagement On Page Lead Form
     */
    const engagementForms = [
        ...document.querySelectorAll('[data-engagement-on-page-lead-form]'),
    ];

    if (engagementForms.length > 0) {
        engagementOnPageLeadForm(engagementForms);
    }

    tracking();
    recruitingChannel();
    modal();

    // Antiscam (we keep it hidden in the middle of our bundle)
    antiScam();

    popover();
    scrollTo();
    accordion();
    usercentricsConfig();
    lazyLoad();
    invertCTA();
    header();
    conversionBanner();
    notificationBanner();
    copyToClipboard();
    monitorCssAvailability();
    carousel();
    tabs();

    abTestSession();

    // Axios Interceptors
    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        Sentry.withScope(scope => {
            // Add additional information to Sentry
            if (error.config?.url) {
                scope.setTag('backend.endpoint', error.config.url);
            }
            if (error.response?.status) {
                scope.setTag('backend.status_code', error.response.status);
            }
            if (error.response?.data?.error) {
                scope.setTag('backend.error', error.response.data.error);
            }

            // Lower the level for some errors
            if (
                error?.response?.status < 500
                || axios.isCancel(error)
                || error.message.includes('Network Error')
                || error.code === "ERR_NETWORK"
            ) {
                scope.setLevel('warning');
            }

            // Send to Sentry
            scope.captureException(error);
        })
        // Pass the error to the next handler for UI feedback
        return Promise.reject(error);
    });

    // AB Tests
    MT3280();
    //
};

window.addEventListener(
    'DOMContentLoaded',
    setup,
);
